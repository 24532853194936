<style  lang="less">
    .address-list .van-address-list__add{
        background: linear-gradient(90deg,rgba(255,200,13,1) 0%,rgba(255,130,0,1) 100%);
        border: none;
    }
    .address-list .van-radio__icon--checked .van-icon{
        background-color: #ed6a0c;
        border-color: #ed6a0c;
    }
    .address-list .van-address-item__tag{
      background: #ed6a0c;
    }
    .not_address{
      text-align: center;
      color: #8a8a8a;
      padding-top: 6rem;
      font-size: 0.88rem;
    }
</style>
<template>
    <div class="address-list gray-bg">
      <go-back-btn></go-back-btn>
        <div class="not_address" v-if="list.length <= 0">还没有地址，去新增一个地址吧！</div>
        <van-address-list
            v-model="chosenAddressId"
            :list="list"
            :switchable="switchable"
            default-tag-text="默认"
            @select="onSelect"
            @add="onAdd"
            @edit="onEdit"
            />
    </div>
</template>
<script>
import goBackBtn from "@/view/Public/goBackBtn"
import { deliveryAddress } from "@/apis/api"
import eventBus from "@/libs/bus"
export default {
  name:"my-address-list",
  components:{ goBackBtn },
  data() {
    return {
      chosenAddressId: '1',
      list: [],
      switchable:false
    }
  },
  mounted(){
    this.getDeliveryAddress()
    if(this.$route.query.isOrderConfirm){
      this.switchable = this.$route.params.isOrderConfirm
    }
  },
  methods: {
    //获取我的收获地址
    getDeliveryAddress(){
        deliveryAddress().then(res=>{
            if(res.code == 0){
                let data = res.res.addresses;
                let newData = []
                data.map(item=>{
                  let newDataItem = {
                    id: item.id,
                    name: item.consignee,
                    areaCode:item.areaCode,
                    tel: item.phone,
                    isDefault: item.isdefault == "0"?false:true,
                    address: item.area + item.address,
                    area:item.address,
                    areattribution_area: item.area,
                    address_str: item.address_str
                  }
                  newData.push(newDataItem)
                })
                this.list = newData
            }else{
                this.$toast(res.msg)
            }
        })
    },
    onAdd() {
      this.$router.push({
          name:"my-address",
          params: { type: 1 }
      })
    },
    onEdit(item, index) {
      this.$router.push({
          name:"my-address",
          params: { type: 2 }
      })
      localStorage.setItem('editAddress',JSON.stringify(item))
    },
    onSelect(item, index){
      //修改收货地址
      this.$store.commit("setTempAddress",item)
      this.$router.go(-1)
    }
  }
}
</script>